import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./help.module.css"

// markup
const Bantuan = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bantuan</title>
        <link rel="canonical" href="https://ktp.melonkotak.com/help" />
      </Helmet>
      <Header />

      <Container className={css.section}>
        <Row>
          <Col>
            <h1>Bantuan</h1>

            <p>
              Berikut beberapa bantuan seputar aplikasi KTP Anabul.
            </p>

            <h2>Contoh Pas Foto yang baik</h2>
            <p>
              KTP Anabul menggunakan teknologi kecerdasan buatan (A.I.) untuk menghapus latar belakang pas foto.
              Namun seringkali hasil masih belum maksimal, berikut tips agar hasil foto bisa terproses dengan baik.

            </p>

            <h3>1. Gunakan Foto Dengan Kualitas Tinggi</h3>
            <p>
              Pastikan kualitas foto cukup jernih dan memiliki kontras yang baik. Tidak terlalu gelap dan tidak terlalu terang.

            </p>
            <img src="https://bucket-prod.ktpanabul.com/assets/help-assets/pass_photo_example_01.webp" alt="Contoh foto yang memiliki kualitas baik" />


            <h3>2. Gunakan Foto Gaya Formal</h3>
            <p>
              Ambil foto dari arah depan dan badan tegap.

            </p>
            <img src="https://bucket-prod.ktpanabul.com/assets/help-assets/pass_photo_example_02.webp" alt="Contoh foto yang memiliki pose formal" />


          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Bantuan
